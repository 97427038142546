<template>
  <!-- 能耗报表S -->
  <div style="padding-top: 50px" class="box fadeIn">
    <van-nav-bar
      :title="title"
      fixed
      left-arrow
      @click-left="$router.back()"
      z-index="999"
    />
    <div class="card_box">
      <div
        style="width: 24%; height: 45%; background-color: aqua"
        v-for="(item, index) in cardList"
        :key="index"
        :style="`background:${item.color}`"
      >
        {{ item.name }}
        <p
          style="
            width: 100%;
            border-top: 1px solid #333;
            margin: 5px 0 8px;
            opacity: 0.4;
          "
        ></p>
        <span
          style="
            font-size: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
          "
          >{{ item.value || 0 }}</span
        >kw·h
      </div>
    </div>
    <!--能耗报表E  -->
    <div class="echarts_box">
      <div class="echarts_item">
        <div class="title">
          <div>
            <span style="font-size: 14px; font-weight: 700; margin-right: 5px"
              >碳排放统计</span
            >
            <van-icon
              size="14"
              name="chart-trending-o"
              @click="(carboneMissionState = 1), getcarboneMissionHistory()"
              v-show="carboneMissionState == 3"
            />
            <van-icon
              size="14"
              name="orders-o"
              @click="(carboneMissionState = 3), getcarboneMission()"
              v-show="carboneMissionState == 1"
            />
          </div>
          <div v-show="carboneMissionState == 1">
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 1)"
            />
          </div>
        </div>
        <span v-if="carboneMissionState == 3">
          <div class="Carbon_item">累计碳排放：{{ carboneMission.SCE }}</div>
          <div class="Carbon_item">小时：{{ carboneMission.HCE }}</div>
          <div class="Carbon_item">日：{{ carboneMission.DCE }}</div>
          <div class="Carbon_item">周：{{ carboneMission.WCE }}</div>
          <div class="Carbon_item">月：{{ carboneMission.MCE }}</div>
        </span>
        <div id="box_Carbon" class="history" v-else></div>
      </div>
      <div class="echarts_item">
        <div class="title">
          <div>时能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 2)"
            />
          </div>
        </div>
        <div id="timeHistory" class="history"></div>
      </div>
      <div class="echarts_item">
        <div class="title">
          <div>日能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 3)"
            />
          </div>
        </div>
        <div id="dayHistory" class="history"></div>
      </div>
      <!-- <div class="echarts_item">
        <div class="title">
          <div>周能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 4)"
            />
          </div>
        </div>
        <van-empty description="暂无数据" style="height: 150px" />
      </div> -->
      <div class="echarts_item" style="margin-bottom: 50px">
        <div class="title">
          <div>月能耗</div>
          <div>
            日期<van-icon
              name="edit"
              style="margin-left: 5px"
              color="#2f9afb"
              @click="(show = true), (pikerNum = 5)"
            />
          </div>
        </div>
        <div id="monthHistory" class="history"></div>
      </div>
    </div>
    <van-popup
      v-model="show"
      round
      position="bottom"
      :style="{ height: '50%' }"
    >
      <!-- 碳排放统计 -->
      <van-datetime-picker
        v-if="pikerNum == 1"
        v-model="carboneMissionDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 时能耗 -->
      <van-datetime-picker
        v-if="pikerNum == 2"
        v-model="hourDate"
        type="month-day"
        title="选择月日"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 日能耗 -->
      <van-datetime-picker
        v-if="pikerNum == 3"
        v-model="dayDate"
        type="year-month"
        title="选择年月"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="changDate"
      />
      <!-- 周能耗 -->
      <!-- 月能耗 -->
      <van-picker
        v-if="pikerNum == 5"
        title="选择年"
        show-toolbar
        :columns="columns"
        @confirm="changDate"
        @cancel="show = false"
        :default-index="4"
      />
    </van-popup>
  </div>
</template>

<script>
let that;
import * as echarts from "echarts";
export default {
  data() {
    return {
      carboneMissionState: 3,
      carboneMission: {
        HCE: "",
        DCE: "",
        MCE: "",
        WCE: "",
        SCE: "",
        CEC: "",
      },
      cardList: [
        { name: "本小时能耗", paramTag: "HE", value: "", color: "#f5640a" },
        { name: "本日能耗", paramTag: "DE", value: "", color: "#66c603" },
        { name: "本周能耗", paramTag: "WE", value: "", color: "#fe4848" },
        { name: "本月能耗", paramTag: "ME", value: "", color: "#0890f1" },
        { name: "上小时能耗", paramTag: "LHE", value: "", color: "#f5640a" },
        { name: "昨日能耗", paramTag: "LDE", value: "", color: "#66c603" },
        { name: "上周能耗", paramTag: "LWE", value: "", color: "#fe4848" },
        { name: "上月能耗", paramTag: "LME", value: "", color: "#0890f1" },
      ],
      columns: [],
      pikerNum: 0,
      show: false,
      title: this.$route.query.pjName,
      ECdata: {},
      minDate: new Date(2023, 0, 1),
      maxDate: new Date(),
      hourDate: new Date(),
      carboneMissionDate: new Date(),
      dayDate: new Date(),
      // hourDate: "",
      monthDate: new Date(),
      getMothHistory(data, XData, ref) {
        let option = {
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "cross",
              label: {
                backgroundColor: "#6a7985",
              },
            },
          },
          toolbox: {
            orient: "vertical",
            feature: {
              saveAsImage: {},
              magicType: { show: true, type: ["line", "bar"] },
            },
          },
          grid: {
            left: "15%",
            bottom: "15%",
            top: "10%",
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: XData,
              axisLabel: {
                inside: false,
                color: "#394b5f",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
              axisLabel: {
                inside: false,
                color: "#394b5f",
              },
              splitLine: {
                lineStyle: {
                  color: "#17e3ff",
                  width: 0.4,
                },
              },
            },
          ],
          series: [
            {
              type: "line",
              stack: "Total",
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: -0.2,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(83, 196, 246, 0.8)",
                    },
                    {
                      offset: 1,
                      //  color: 'rgba(255, 230, 0, 0.2)'
                      color: "rgba(83, 196, 246, 0.2)",
                    },
                  ],
                },
              },
              emphasis: {
                focus: "series",
              },
              data: data,
              itemStyle: {
                color: "rgb(83, 196, 246)",
              },
            },
          ],
        };

        var chartDom = document.getElementById(ref);
        var myChart = echarts.init(chartDom);
        myChart.setOption(option);
      },
    };
  },
  created() {
    that = this;
    this.getParam();
  },
  methods: {
    // 获取实时数据
    getParam() {
      let data = {
        paramTags: this.cardList.map((item) => item.paramTag),
        projectId: this.$route.query.projectId * 1,
      };
      this.$api.energy.getParams(data).then((res) => {
        if (res.code == 200) {
          this.cardList.forEach((item) => {
            res.data.find((it) => {
              if (item.paramTag == it.paramTag) {
                item.value = it.value;
                return true;
              }
            });
          });
        }
      });
    },
    changDate(val) {
      this.show = false;
      switch (this.pikerNum) {
        case 1:
          this.carboneMissionDate = val;
          this.getcarboneMissionHistory();
          break;
        case 2:
          this.hourDate = val;
          this.selectDayTags();
          break;
        case 3:
          this.dayDate = val;
          this.selectMothTags();
          break;
        case 4:
          this.weekDate;
          break;
        case 5:
          this.monthDate = val;
          this.selectYearTags();
          break;
        default:
          break;
      }
    },
    selectDayTags() {
      that.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let from = {
        paramTags: ["HE"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.hourDate).format("YYYY-MM-DD"),
        historyType: 2,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        that.$toast.clear();
        if (res.code == 200) {
          let data = [];
          let xData = [
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "10",
            "11",
            "12",
            "13",
            "14",
            "15",
            "16",
            "17",
            "18",
            "19",
            "20",
            "21",
            "22",
            "23",
            "24",
          ];
          let obj = {};
          res.data.data[0].stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          xData.forEach((item) => {
            let num = obj[item] ? obj[item] : 0;
            if (item == 24) {
              num = obj["0"] ? obj["0"] : 0;
            }
            data.push(num);
          });
          that.getMothHistory(data, xData, "timeHistory");
        }
      });
    },
    //  获取日能耗统计
    selectMothTags() {
      that.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let from = {
        paramTags: ["DE"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.dayDate).format("YYYY-MM"),
        historyType: 3,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        that.$toast.clear();
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let xDate = this.dayjs(that.dayDate).daysInMonth();
          let XData = [];
          for (let i = 1; i <= xDate; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "dayHistory");
        }
      });
    },
    //  获取月能耗统计
    selectYearTags() {
      that.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let from = {
        paramTags: ["ME"],
        projectId: this.$route.query.projectId * 1,
        date: this.dayjs(that.monthDate).format("YYYY"),
        historyType: 4,
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        that.$toast.clear();
        if (res.code == 200) {
          let data = [];
          let obj = {};
          res.data.data[0]?.stream.forEach((item) => {
            obj = { ...obj, ...item };
          });
          let XData = [];
          for (let i = 1; i <= 12; i++) {
            XData.push(`${i}`);
            let num = obj[i] ? obj[i] : 0;
            data.push(num);
          }
          that.getMothHistory(data, XData, "monthHistory");
        }
      });
    },
    // 碳排放量
    getcarboneMission() {
      that.$api.energy
        .getParams({
          projectId: this.$route.query.projectId * 1,
          paramTags: ["SCE", "HCE", "DCE", "WCE", "MCE", "CEC"],
        })
        .then((res) => {
          if (res.code == 200) {
            res.data.forEach((item) => {
              that.carboneMission[item.paramTag] = item.value;
            });
          }
        });
    },
    //碳排放历史数据
    getcarboneMissionHistory() {
      that.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      let from = {
        projectId: this.$route.query.projectId * 1,
        paramTags: ["LDCE"],
        historyType: 6,
        date: this.dayjs(that.carboneMissionDate).format("YYYY-MM"),
      };
      that.$api.energy.getHistoryData(from).then((res) => {
        that.$toast.clear();
        if (res.code == 200) {
          that.carboneList = [];
          let xDate = this.dayjs(from.date).daysInMonth();
          let data = [];
          let XData = [];
          let obj = {};
          res.data.data?.forEach((item) => {
            obj = { ...obj, ...item };
          });
          for (let i = 1; i <= xDate; i++) {
            XData.push(`${i}`);
            if (obj[i]) {
              obj[i].forEach((it) => {
                data.push(it.LDCE * 1);
              });
            } else {
              data.push(0);
            }
            that.carboneList.push({ label: i, value: data[i - 1] });
          }
          this.$nextTick(() => {
            that.getMothHistory(data, XData, "box_Carbon");
          });
        }
      });
    },
  },
  mounted() {
    const num = this.dayjs().format("YYYY");
    let arr = [];
    for (let i = 0; i < 5; i++) {
      if (i == 0) {
        arr.push(num);
      } else {
        arr.push(num * 1 - i);
        arr.push(num * 1 + i);
      }
    }
    this.columns = arr.sort();
    that.selectDayTags();
    that.selectMothTags();
    that.selectYearTags();
    that.getcarboneMission();
    // that.selectweekTags();
  },
};
</script>
<style scoped lang="less">
.box {
  height: 100%;
  overflow-y: auto;
}
.van-col {
  height: 50px;
  background-color: #ffd967;
  border-radius: 10px;
  text-align: center;
  white-space: nowrap;
  font-size: 12px !important;
  margin-bottom: 5px;
  color: #ffffff !important;
  font-weight: 700;
  line-height: 24px;
  span {
    color: #444;
    margin-left: 5px;
    font-size: 12px !important;
  }
}
.echarts_box {
  text-align: left;
  padding: 0 10px;
  .echarts_item {
    height: 200px;
    .title {
      display: flex;
      justify-content: space-between;
    }
    .history {
      width: 100%;
      height: 180px;
    }
  }
}
::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
/* 隐藏 IE、Edge 和 Firefox 的滚动条 */
.example {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
.card_box {
  height: 20%;
  padding: 10px 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: #fff;
  color: #fff;
  font-weight: 700;
  text-align: center;
}
.card_box div {
  border-radius: 5px;
}
.Carbon_item {
  width: calc(100% - 20);
  height: 30px;
  background-color: #50add8;
  margin: 5px 0;
  line-height: 30px;
  border-radius: 5px;
  padding-left: 20px;
  color: #fff;
}
/* @import url(); 引入css类 */
</style>
